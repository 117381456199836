import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { MatDialogModule } from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HttpClientModule} from '@angular/common/http';
import { ApiService } from './services/api.service';
import { ScriptService } from './services/script.service';
import { SEOService } from './services/seo.service';
import { MarkdownModule } from 'ngx-markdown';
import { MobileDevelopmentComponent } from './components/pages/services/mobile-development/mobile-development.component';
import { DesktopDevelopmentComponent } from './components/pages/services/desktop-development/desktop-development.component';
import { BackendDevelopmentComponent } from './components/pages/services/backend-development/backend-development.component';
import { CustomSoftwareDevelopmentComponent } from './components/pages/services/custom-software-development/custom-software-development.component';
import { TeamExtensionComponent } from './components/pages/services/team-extension/team-extension.component';
import { TrainingComponent } from './components/pages/services/training/training.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogPostComponent } from './components/pages/blog-post/blog-post.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ServicesComponent } from './components/pages/services/services/services.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { AndroidComponent } from './components/pages/technologies/android/android.component';
import { IosComponent } from './components/pages/technologies/ios/ios.component';
import { ElectronComponent } from './components/pages/technologies/electron/electron.component';
import { JavafxComponent } from './components/pages/technologies/javafx/javafx.component';
import { AngularComponent } from './components/pages/technologies/angular/angular.component';
import { ReactComponent } from './components/pages/technologies/react/react.component';
import { VueComponent } from './components/pages/technologies/vue/vue.component';
import { WordpressComponent } from './components/pages/technologies/wordpress/wordpress.component';
import { ScalaComponent } from './components/pages/technologies/scala/scala.component';
import { JavaComponent } from './components/pages/technologies/java/java.component';
import { PythonComponent } from './components/pages/technologies/python/python.component';
import { NodeComponent } from './components/pages/technologies/node/node.component';
import { FlutterComponent } from './components/pages/technologies/flutter/flutter.component';
import { ReactNativeComponent } from './components/pages/technologies/react-native/react-native.component';
import { CareerComponent } from './components/pages/career/career.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { FrontendDevelopmentComponent } from './components/pages/services/frontend-development/frontend-development.component';
import { TechnologiesComponent } from './components/pages/technologies/technologies/technologies.component';
import { IonicComponent } from './components/pages/technologies/ionic/ionic.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { SwiperModule } from 'swiper/angular';
import { NgxTypedWriterModule } from './libs/ngx-typed-writer/src';
import { environment } from 'src/environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';


const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.URL // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#ff0066'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BlogComponent,
    BlogPostComponent,
    ServicesComponent,
    MobileDevelopmentComponent,
    DesktopDevelopmentComponent,
    BackendDevelopmentComponent,
    CustomSoftwareDevelopmentComponent,
    TeamExtensionComponent,
    TrainingComponent,
    ContactComponent,
    AboutComponent,
    FaqComponent,
    AndroidComponent,
    IosComponent,
    ElectronComponent,
    JavafxComponent,
    AngularComponent,
    ReactComponent,
    VueComponent,
    WordpressComponent,
    ScalaComponent,
    JavaComponent,
    PythonComponent,
    NodeComponent,
    FlutterComponent,
    ReactNativeComponent,
    CareerComponent,
    PortfolioComponent,
    FrontendDevelopmentComponent,
    TechnologiesComponent,
    IonicComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MarkdownModule.forRoot(),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SwiperModule,
    NgxTypedWriterModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    RecaptchaV3Module
  ],
  providers: [
    ApiService,
    ScriptService,
    SEOService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.SITE_KEY,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule {
}
