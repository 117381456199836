<section class="contact-us  position-relative overflow-hidden" style="padding-top: 100px; padding-bottom: 50px;"
  id="footer">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-xl-5 col-lg-5 col-md-12">
        <div class="section-heading">
          <h4 class="h5 text-primary">Quick Support</h4>
          <h2>Get in Touch Today!</h2>
          <p>
            We will reach out to you in less than 48 hours to talk about your needs.
          </p>
        </div>
        <div class="row justify-content-between pb-5">
          <div class="col-sm-6 mb-4 mb-md-0 mb-lg-0">
            <div class="icon-box d-inline-block rounded-circle bg-primary-soft">
              <i class="fas fa-phone fa-2x text-primary"></i>
            </div>
            <div class="contact-info">
              <h5>Call Us</h5>
              <p>Questions about our services or pricing? Call for support</p>
              <ul class="list-unstyled">
                <li class="text-decoration-none"><strong>Africa:</strong> <a href="tel:+237678474693"
                    class="read-more-link text-decoration-none"><i class="far fa-phone me-2"></i>+237 678474693</a></li>
                <li><strong>Europe:</strong> <a href="tel:+352661906796"
                    class="read-more-link text-decoration-none"><i class="far fa-phone me-2"></i>+352 661 906 796</a>
                </li>
              </ul>


            </div>
          </div>
          <div class="col-sm-6">
            <div
              class="icon-box d-inline-block rounded-circle bg-danger-soft animate__animated animate__bounceIn animate__slower animate__repeat-3">
              <i class="fas fa-comment-alt-lines fa-2x text-danger"></i>
            </div>
            <div class="contact-info">
              <h5>Chat Us</h5>
              <p>
                Our support will help you from
                <strong>9:00 AM to 5:00 PM CET.</strong>
              </p>
              <a href="mailto:info@hiis.io" target="_blank" class="read-more-link text-decoration-none"><i class="far fa-comment-alt-dots me-2"></i>
                Send Email Directly</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-7 col-md-12">
        <div class="register-wrap p-5 bg-white shadow rounded-custom position-relative">
          <form class="register-form position-relative z-5" #contactForm="ngForm" (ngSubmit)="submit()">
            <h3 class="mb-5 fw-medium">
              Fill out the form and we'll be in touch as soon as possible.
            </h3>
            <div class="row">
              <div class="col-sm-6">
                <div class="input-group mb-3">
                  <input [(ngModel)]="contact.name" type="text" class="form-control" name="Name" placeholder="Name *"
                    aria-label="name" required="true" id="name" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group mb-3">
                  <input [(ngModel)]="contact.email" type="email" class="form-control" placeholder="Email *"
                    aria-label="email" name="email" required="true" id="email" email />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group mb-3">
                  <input [(ngModel)]="contact.company" type="text" class="form-control" placeholder="Company name"
                    aria-label="company-name" name="company" id="company" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group mb-3">
                  <input [(ngModel)]="contact.website" type="text" class="form-control" placeholder="Company website"
                    aria-label="company-website" name="website" id="website"
                    pattern="^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group mb-3">
                  <select [(ngModel)]="contact.budget" class="form-control form-select" name="budget" id="budget"
                    required="true" data-msg="Please select your budget.">
                    <option value="Budget" selected="true" disabled="">Budget *</option>
                    <option *ngFor="let item of budgets" value={{item}}>{{item}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-group mb-3">
                  <select [(ngModel)]="contact.country" class="form-control form-select" name="country" id="country"
                    required="true" data-msg="Please select your country.">
                    <option value="Country" selected="true" disabled="">Country *</option>
                    <option *ngFor="let item of countryList" value={{item}}>{{item}}</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group mb-3">
                  <textarea [(ngModel)]="contact.message" class="form-control" required="true"
                    placeholder="Tell us more about your project, needs and budget" style="height: 120px"
                    name="message"></textarea>
                </div>
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input class="form-check-input" [(ngModel)]="consent" type="checkbox" value={{consent}} id="flexCheckChecked" name="consent" required />
                  <label class="form-check-label small" for="flexCheckChecked">
                    HIIS will process your information to contact you and may use it for stats or updates on our
                    products/services.

                  </label>
                </div>
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-primary mt-4 d-block w-100" type="submit" [disabled]="isLoading ||
                  !(contactForm.valid  && isRequirementsSet())">{{buttonMsg}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gradient position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5" style="
        background: url('assets/img/shape/dot-dot-wave-shape.svg') no-repeat
          center top;
      ">
    <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
    <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-pinkright-5"></div>
  </div>
</section>

<footer class="footer-section">
  <!--footer top start-->
  <!--for light footer add .footer-light class and for dark footer add .bg-dark .text-white class-->
  <div class="footer-top  bg-gradient text-white ptb-120"
    style="background: url('assets/img/page-header-bg.svg')no-repeat bottom right">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
          <div class="footer-single-col">
            <div class="footer-single-col mb-4">
              <img src="assets/img/logo-white.png" alt="logo" class="img-fluid logo-white">
              <img src="assets/img/logo-color.png" alt="logo" class="img-fluid logo-color">
            </div>
            <p>Our latest news, articles, and resources, we will sent to
              your inbox weekly.</p>

            <form class="newsletter-form position-relative d-block d-lg-flex d-md-flex">
              <input type="text" class="input-newsletter form-control me-2" placeholder="Enter your email" name="email"
                required="" autocomplete="off">
              <input type="submit" value="Subscribe" data-wait="Please wait..."
                class="btn btn-primary mt-3 mt-lg-0 mt-md-0">
            </form>
            <div class="ratting-wrap mt-4">
              <h6 class="mb-0">4.5/5 Overall rating</h6>
              <ul class="list-unstyled rating-list list-inline mb-0">
                <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                <li class="list-inline-item"><i class="fas fa-star-half text-pink"></i></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
          <div class="row">
            <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
              <div class="footer-single-col">
                <h3>Hiis</h3>
                <ul class="list-unstyled footer-nav-list mb-lg-0">
                  <li><a routerLink="." [fragment]="'about'" skipLocationChange class="text-decoration-none">About</a>
                  </li>
                  <li><a routerLink="." [fragment]="'services'" skipLocationChange
                      class="text-decoration-none">Services</a></li>
                  <li><a routerLink="." [fragment]="'why-us'" skipLocationChange class="text-decoration-none">Why
                      Hiis</a></li>
                  <li><a routerLink="." [fragment]="'testimonials'" skipLocationChange
                      class="text-decoration-none">Testimonials</a>
                  </li>
                  <li><a routerLink="." [fragment]="'our-process'" skipLocationChange class="text-decoration-none">Our
                      Process</a>
                  </li>
                  <li><a routerLink="." [fragment]="'technologies'" skipLocationChange
                      class="text-decoration-none">Technologies</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
              <div class="footer-single-col">
                <h3>Services</h3>
                <ul class="list-unstyled footer-nav-list mb-lg-0">
                  <li><a routerLink="." [fragment]="'services'" skipLocationChange class="text-decoration-none"><i
                        class="fa fa-mobile-button"></i> Mobile Development</a></li>
                  <li><a routerLink="." [fragment]="'services'" skipLocationChange class="text-decoration-none"><i
                        class="fab fa-html5"></i> Frontend Development</a></li>
                  <li><a routerLink="." [fragment]="'services'" skipLocationChange class="text-decoration-none"><i
                        class="devicon-graphql-plain"></i> Backend Development</a></li>
                  <li><a routerLink="." [fragment]="'services'" skipLocationChange class="text-decoration-none"><i
                        class="fa fa-laptop-code"></i> Custom Software</a></li>
                  <li><a routerLink="." [fragment]="'services'" skipLocationChange class="text-decoration-none"><i
                        class="fad fa-poll-people"></i> Team Extension</a>
                  </li>
                  <li><a routerLink="." [fragment]="'services'" skipLocationChange class="text-decoration-none"><i
                        class="fa fa-graduation-cap"></i> Training</a></li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
              <div class="footer-single-col">
                <h3>Technologies</h3>
                <ul class="list-unstyled footer-nav-list mb-lg-0">
                  <li><a routerLink="." [fragment]="'technologies'" skipLocationChange class="text-decoration-none"><i
                        class="devicon-android-plain"></i> Android</a></li>
                  <li><a routerLink="." [fragment]="'technologies'" skipLocationChange class="text-decoration-none"><i
                        class="devicon-apple-plain"></i> IOS</a></li>
                  <li><a routerLink="." [fragment]="'technologies'" skipLocationChange class="text-decoration-none"><i
                        class="devicon-angularjs-plain"></i> Angular</a></li>
                  <li><a routerLink="." [fragment]="'technologies'" skipLocationChange class="text-decoration-none"><i
                        class="devicon-react-plain"></i> React</a></li>
                  <li><a routerLink="." [fragment]="'technologies'" skipLocationChange class="text-decoration-none"><i
                        class="devicon-wordpress-plain"></i> Wordpress</a></li>
                  <li><a routerLink="." [fragment]="'technologies'" skipLocationChange class="text-decoration-none"><i
                        class="devicon-scala-plain"></i> Scala</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!--footer top end-->

  <!--footer bottom start-->
  <div class="footer-bottom  bg-gradient text-white py-4">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-4 col-lg-4">
          <div class="copyright-text">
            <p class="mb-lg-0 mb-md-0">&copy; {{year}} Hiis All Rights Reserved.</p>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="copyright-text">
            <!-- <p class="mb-lg-0 mb-md-0"><a routerLink="/privacy-policy" class="text-decoration-none">Privacy
                                Policy</a> | <a routerLink="/terms-condition" class="text-decoration-none">Terms &
                                Conditions</a></p> -->
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="footer-single-col text-start text-lg-end text-md-end">
            <ul class="list-unstyled list-inline footer-social-list mb-0">
              <li class="list-inline-item"><a
                  href="https://www.facebook.com/profile.php?id=100070216147516&mibextid=LQQJ4d" target="_blank"><i
                    class="fab fa-facebook-f"></i></a></li>
              <li class="list-inline-item"><a href="https://cm.linkedin.com/company/hiis" target="_blank"><i
                    class="fab fa-linkedin"></i></a></li>
              <li class="list-inline-item"><a href="https://github.com/Hiis-io" target="_blank"><i
                    class="fab fa-github"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--footer bottom end-->
</footer>
