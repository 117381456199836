<ngx-json-ld [json]="schema"></ngx-json-ld>
<!--hero section start-->
<section class="hero-section ptb-120 text-black bg-gradient"
  style="background: url('assets/img/hero-dot-bg.png') no-repeat center right">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-10 col-xs-12">
        <div class="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
          <div class="typewriter-top">
            <h1 class="fw-bold display-5 typewriter-heading">Welcome to <ngx-typed-writer [strings]="['High-Is, Oops!','HIIS [hai-iz]']"
              [cursorChar]="'_'" [showCursor]="false" [backSpeed]="30" [typeSpeed]="20" [loop]="false">
            </ngx-typed-writer>
          </h1>
            <p class="lead typewriter-body"><ngx-typed-writer
                [strings]="['HIIS [haɪ-ɪz] pronounced High-Is is a full-service software development company with extensive expereince building large scale applications.', 'We transform bold business ideas into exceptional digital products. Let\'s work together']"
                [cursorChar]="'_'" [showCursor]="true" [backSpeed]="3" [typeSpeed]="20" [loop]="true">
              </ngx-typed-writer>
            </p>
          </div>
          <div class="typewriter-bottom">
            <div class="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
              <a routerLink="." fragment="footer" class="btn btn-primary me-3">Talk to an expert</a>
            </div>
            <div class="row justify-content-lg-start mt-40">
              <h6 class="text-white-70 mb-2">Our Top technologies:</h6>
              <div class="text-start">
                <ul class="list-unstyled list-inline tech-list mb-0">
                  <li class="list-inline-item"><i class="fa-2x devicon-flutter-plain colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-react-original colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-scala-plain colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-android-plain colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-apple-original colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-mongodb-plain colored"></i></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-8 mt-5 d-none d-sm-block">
        <div class="hero-img position-relative circle-shape-images">
          <!--animated shape start-->
          <ul class="position-absolute animate-element parallax-element circle-shape-list">
            <!-- <li class="layer" data-depth="0.03">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-0 hero-1">
            </li> -->
            <li class="layer" data-depth="0.02">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-1 hero-1">
            </li>
            <li class="layer" data-depth="0.04">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-2 hero-1">
            </li>
            <li class="layer" data-depth="0.04">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-3 hero-1">
            </li>
            <li class="layer" data-depth="0.03">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-4 hero-1">
            </li>
            <li class="layer" data-depth="0.03">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-5 hero-1">
            </li>
          </ul>
          <!--animated shape end-->
          <img src="assets/img/lion-square.png" alt="hero img" class="img-fluid position-relative z-5">
        </div>
      </div>
    </div>
  </div>
</section>
<!--hero section end-->

<!--our story section start-->
<section class="our-story-section pt-60 pb-120" id="about"
    style="background: url('assets/img/shape/dot-dot-wave-shape.svg') no-repeat left bottom">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-lg-5 col-md-12 order-lg-1">
                <div class="section-heading sticky-sidebar">
                    <h4 class="h5 text-primary">Our Story</h4>
                    <h2>More than just
                        a software house</h2>
                    <p>Hiis is a place where over 10 passionate professionals align your business goals with the latest
                        tech solutions. We are young, daring, and we want to change the world. How? By pairing up our
                        most brilliant talents with our most demanding clients. Meet the team behind the most
                        customer-oriented software company you’ve ever worked with – and get on board</p>
                    <div class="mt-4">
                        <h6 class="mb-3">We Are Awarded By-</h6>
                        <img src="assets/img/awards-01.svg" alt="awards" class="me-4 img-fluid">
                        <img src="assets/img/awards-02.svg" alt="awards" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-0">
                <div class="story-grid-wrapper position-relative">
                    <!--animated shape start-->
                    <ul class="position-absolute animate-element parallax-element shape-service d-none d-lg-block">
                        <li class="layer" data-depth="0.02">
                            <img src="assets/img/color-shape/image-2.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-2 z-5">
                        </li>
                        <li class="layer" data-depth="0.03">
                            <img src="assets/img/color-shape/feature-3.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-3">
                        </li>
                    </ul>
                    <!--animated shape end-->
                    <div class="story-grid rounded-custom bg-dark overflow-hidden position-relative">
                        <div class="story-item bg-light border">
                            <h3 class="display-5 fw-bold mb-1 text-success">4.8</h3>
                            <h6 class="mb-0">Client Rating</h6>
                        </div>
                        <div class="story-item bg-white border">
                            <h3 class="display-5 fw-bold mb-1 text-primary">10+</h3>
                            <h6 class="mb-0">Team Members</h6>
                        </div>
                        <div class="story-item bg-white border">
                            <h3 class="display-5 fw-bold mb-1 text-dark">1.5M+</h3>
                            <h6 class="mb-0">Lines of Code</h6>
                        </div>
                        <div class="story-item bg-light border">
                            <h3 class="display-5 fw-bold mb-1 text-pink">3+</h3>
                            <h6 class="mb-0">Years In Business</h6>
                        </div>
                        <div class="story-item bg-light border">
                            <h3 class="display-5 fw-bold mb-1 text-danger">20+</h3>
                            <h6 class="mb-0">Clients Worldwide</h6>
                        </div>
                        <div class="story-item bg-white border">
                            <h3 class="display-5 fw-bold mb-1 text-primary">30+</h3>
                            <h6 class="mb-0">Projects Completed</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--our story section end-->

<!--feature promo section start-->
<section class="feature-promo ptb-120 bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="section-heading text-center">
          <h2>You share your idea. We get it done</h2>
          <p>Build world-class digital products with a team of design, development and strategy experts. All
            in one place.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <div class="single-feature-promo p-lg-5 p-4 text-center mt-3">
          <div class="feature-icon icon-center pb-5 rounded-custom bg-primary">
            <i class="fal fa-layer-group fa-2x text-white"></i>
          </div>
          <div class="feature-info-wrap">
            <h3 class="h5">Good Performance</h3>
            <p>The technologies we use for building our projects are carefully picked to create highly
              performant, scalable and concurrent applications.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-feature-promo p-lg-5 p-4 text-center mt-3">
          <div class="feature-icon icon-center pb-5 rounded-custom bg-pink">
            <i class="fal fa-shield-check fa-2x text-white"></i>
          </div>
          <div class="feature-info-wrap">
            <h3 class="h5">Highly Secure</h3>
            <p>Secuity is at the core of every successful software project. The system we design are highly
              secure and based on industry standards. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-feature-promo p-lg-5 p-4 text-center mt-3">
          <div class="feature-icon icon-center pb-5 rounded-custom bg-dark">
            <i class="fal fa-code fa-2x text-white"></i>
          </div>
          <div class="feature-info-wrap">
            <h3 class="h5">Fast Development</h3>
            <p>Cut off development time to nearly 50% with our team of highly trained software developers.
              Get your idea to the market within some few months.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-lg-5 pt-3">
      <div class="col-lg-6 mt-4">
        <div class="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom">
          <div class="cta-left-info mb-2">
            <h5>Amazing technologies</h5>
            <p>We build amazing softwares for our clients. You will love working with Hiis. </p>
          </div>
          <div class="mt-auto">
            <a routerLink="." [fragment]="'footer'" skipLocationChange class="btn btn-outline-primary btn-sm">Start For Free</a>
          </div>
          <div class="cta-img position-absolute right-0 bottom-0">
            <img src="assets/img/cta-img-1.png" alt="cta img" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-4">
        <div class="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom">
          <div class="cta-left-info mb-2">
            <h5>Excellent user experience</h5>
            <p>Love it when the UI is beautiful and effective. So do we! Talk to us today.</p>
          </div>
          <div class="mt-auto">
            <a routerLink="." [fragment]="'footer'" skipLocationChange class="btn btn-outline-primary btn-sm">Start For Free</a>
          </div>
          <div class="cta-img position-absolute right-0 bottom-0">
            <img src="assets/img/cta-img-2.png" alt="cta img" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--features grid section start-->
<section class="feature-section bg-light" id="services">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="section-heading text-center">
          <h2>Our Services</h2>
          <p>Build world-class digital products with a team of design, development and strategy experts. All
            in one place.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="feature-grid">
          <div class="feature-card bg-white shadow-sm highlight-card rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-primary-soft mb-32">
              <i class="fal fa-mobile-button icon-md text-primary"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Mobile Development</h3>
              <p>It takes lots of effort, planning and research to create an app that succeeds. We want to
                take the work off your shoulders. Our UIs are beautiful and intuitive, your users will
                love them.</p>
              <h6 class="mt-4">Included with...</h6>
              <ul class="list-unstyled mb-0">
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Cross Platform
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Android
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>IOS
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Beautiful UI
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Great UX
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Clean and
                  modern
                </li>
              </ul>
            </div>
            <a routerLink="." [fragment]="'footer'" skipLocationChange class="link-with-icon text-decoration-none mt-3">Contact Us <i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-primary mb-32">
              <i class="fas devicon-graphql-plain icon-md text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Backend Development</h3>
              <p class="mb-0">Today it's customary to design your tech infrastructures using a micro
                service architecture. We are here to help you extends your APIs as you wish. Our team is
                well versed with several backend languages and frameworks.</p>
            </div>
            <a routerLink="." [fragment]="'footer'" skipLocationChange class="link-with-icon text-decoration-none mt-3">Contact Us <i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-pink mb-32">
              <i class="fab fa-html5 icon-md text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Frontend Development</h3>
              <p class="mb-0">Building professional grade Web Apps requires a lot of efforts and steps to
                get it right, reason why we are here to offer our expertise in building highly scalable
                Web apps be it Web 2 or Web 3 (Smart Contracts)</p>
            </div>
            <a routerLink="." [fragment]="'footer'" skipLocationChange class="link-with-icon text-decoration-none mt-3">Contact Us <i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-pink mb-32">
              <i class="fa fa-laptop-code icon-sm text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Custom Software Development</h3>
              <p class="mb-0">In need of a custom software for your organization? Our team of senior
                software engineers can make that possible. We create applications that astound users and
                bring measurable business results.</p>
            </div>
            <a routerLink="." [fragment]="'footer'" skipLocationChange class="link-with-icon text-decoration-none mt-3">Contact Us<i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-primary mb-32">
              <i class="fa fa-graduation-cap icon-sm text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Training & Mentoring</h3>
              <p class="mb-0">With the growing need of developers around the world, we train & mentor
                young developers in the languages and technologies we master in order to make them ready
                for the international job market.</p>
            </div>
            <a routerLink="." [fragment]="'footer'" skipLocationChange class="link-with-icon text-decoration-none mt-3">Contact Us<i
                class="far fa-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--features grid section end-->

<!--why choose us section start-->
<section class="why-choose-us ptb-120  bg-light" id="why-us">
  <div class="container">
    <div class="row justify-content-lg-between align-items-center">
      <div class="col-lg-5 col-12">
        <div class="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
          <img src="assets/img/lion-square.png" class="img-fluid" alt="feature-image">
        </div>
      </div>
      <div class="col-lg-7 col-12">
        <div class="why-choose-content">
          <div class="icon-box rounded-custom bg-primary shadow-sm d-inline-block">
            <i class="fal fa-shield-check fa-2x text-white"></i>
          </div>
          <h2>Why choose us</h2>
          <p>Searching for a partner that will take the process of software development off your hands? You’ve
            come to the right place. We ideate, design, and develop data-driven digital products made to
            answer business challenges. We offer 360° services to smoothly guide you on your way to creating
            a seamless digital masterpiece.</p>
          <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Honesty</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Motivated people</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Financial integrity
            </li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Better win rates</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Honoring our word</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Sales compliance</li>
          </ul>
          <a href="../about" class="read-more-link text-decoration-none">Know More About Us <i
              class="far fa-arrow-right ms-2"></i></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--why choose us section end-->

<!--customer review tab section start-->
<section class="customer-review-tab ptb-100 bg-gradient text-white  position-relative z-2" id="testimonials">
  <div class="container">
    <div class="row justify-content-center align-content-center">
      <div class="col-md-10 col-lg-6">
        <div class="section-heading text-center">
          <h4 class="h5 text-pink text-primary">Testimonials</h4>
          <h2>What They Say About Us</h2>
          <p>We have helped over 10+ companies succeed in their industries with our top quality solutions.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tab-content" id="testimonial-tabContent">
          <div class="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">Incredible Backend Team!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>

                  <blockquote class="blockquote">
                    <p>Working with this team on our backend development was a game-changer. They helped streamline our
                      processes and optimized our server infrastructure. The result? Faster, more reliable performance
                      for our customers. Their expertise in scalable backend solutions has been invaluable.</p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">TechSolve Inc.</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/woman-in-office.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <!-- <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div> -->
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-2" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">Shopify Pros!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>When we needed a custom e-commerce solution, this team delivered in spades. They built a web
                      platform that’s fast, secure, and scalable. The entire process, from design to deployment, was
                      seamless, and we’ve experienced significant growth since launch.</p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">BrightTech Innovations</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/shop-square.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <!-- <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div> -->
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-3" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">Incredible UI/UX Developers!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>
                      We had a complex app idea, and this team brought it to life beautifully. Their deep knowledge of
                      mobile development and user experience helped us launch an app that’s intuitive and powerful. Our
                      users love the new features, and the app runs flawlessly.
                    </p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">MobileGo Solutions</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/social-app.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <!-- <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div> -->
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-4" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">Best team we have worked with!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>This development team’s mobile expertise helped us create an educational app that’s both engaging
                      and easy to navigate. They understood our goals and delivered a beautiful, high-performance app
                      that our students and educators love using. We couldn’t be happier with the results. </p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">Ecole Rollingergrund</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/school.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <!-- <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div> -->
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-5" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">5 Star Team.</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>Our partnership with this team completely transformed our backend architecture. They seamlessly
                      integrated new technologies into our existing system and improved overall efficiency. They’re not
                      just developers; they’re problem solvers who understand both the tech and the business." </p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">InnovateX Solutions</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/process.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <!-- <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div> -->
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-pills testimonial-tab-menu mt-60" id="testimonial" role="tablist">
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-1" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/sarah.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Sarah L.</h6>
                <span>CEO</span>
              </div>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-2" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/2.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Mark T.</h6>
                <span>E-Commerce Lead</span>
              </div>
            </div>

          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-3" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/6.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">John K.</h6>
                <span>Product Manager</span>
              </div>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-4" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/5.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Patricia N.</h6>
                <span>Educator</span>
              </div>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-5" role="tab" aria-selected="true">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/3.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Ami Nijai</h6>
                <span>CTO</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--customer review tab section end-->

<!--our work process start-->
<section class="work-process ptb-100 bg-light" id="our-process">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="section-heading text-center">
          <h4 class="h5 text-primary">Process</h4>
          <h2>How we work at HIIS</h2>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-5 col-md-12 order-1 order-lg-0">
        <div class="img-wrap color-gray">
          <img src="assets/img/hiis-long.png" alt="work process" class="img-fluid rounded-custom">
        </div>
      </div>
      <div class="col-lg-6 col-md-12 order-0 order-lg-1">
        <ul class="work-process-list list-unstyled">
          <li class="d-flex align-items-start mb-4">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="far fa-envelope fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 1</span>
              <h3 class="h5 mb-2">Send us a message</h3>
              <p>You send us a message through our website with details about your project. We will
                respond to you as quickly as possible, usually less than 12H.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary text-white me-4 mt-2">
              <i class="far fa-brain fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 2</span>
              <h3 class="h5 mb-2">Our team accesses the opportunity</h3>
              <p>Our team of senior engineers will access the opportunities. This process usually takes up
                to 1 working day.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="far fa-mobile-iphone fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 3</span>
              <h3 class="h5 mb-2">We jump on a ~1h Discovery Call with you</h3>
              <p>Interactively whiteboard transparent testing procedures before
                bricks-and-clicks initiatives administrate competencies.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4 mb-lg-0">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="far fa-calendar-clock fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 4</span>
              <h3 class="h5 mb-2">We prepare an offer with a project roadmap</h3>
              <p>We prepare an offer with a roadmap and project intake plan, based on the best effort to
                budget ratio.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4 mb-lg-0">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="fad fa-handshake fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 5</span>
              <h3 class="h5 mb-2">We negotiate agreement</h3>
              <p>Takes up to 6 working days - the time may be shorter depending on your legal team's
                responsiveness.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4 mb-lg-0">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="fad fa-check-double fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 6</span>
              <h3 class="h5 mb-2">Hire/Project Development</h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--our work process end-->

<!--integration section start-->
<section class="integration-section ptb-120" id="technologies">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-3">
        <div class="integration-list-wrap">
          <div class="integration-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Scala">
            <i class="fa-2x dev devicon-scala-plain colored "></i>
          </div>
          <div class="integration-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Java">
            <i class="fa-2x dev devicon-java-plain-wordmark colored "></i>
          </div>
          <div class="integration-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Python">
            <i class="fa-2x dev devicon-python-plain colored rounded-circle"></i>
          </div>

          <div class="integration-4" data-bs-toggle="tooltip" data-bs-placement="top" title="Rust">
            <i class="fa-2x dev devicon-rust-plain colored "></i>
          </div>
          <div class="integration-5" data-bs-toggle="tooltip" data-bs-placement="top" title="Php">
            <i class="fa-2x dev devicon-php-plain colored "></i>
          </div>
          <div class="integration-6" data-bs-toggle="tooltip" data-bs-placement="top" title="Typescript">
            <i class="fa-2x dev devicon-typescript-plain colored "></i>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="section-heading text-center my-5 my-lg-0 my-xl-0">
          <h4 class="text-primary h5">Our Top Technologies</h4>
          <h3>We use the best resources to produce high quality softwares.</h3>
          <a routerLink="." [fragment]="'footer'" skipLocationChange class="mt-4 btn btn-primary">Send Us a Message</a>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="col-lg-4">
          <div class="integration-list-wrap">
            <div class="integration-7" data-bs-toggle="tooltip" data-bs-placement="top" title="React">
              <i class="fa-2x dev devicon-react-original colored "></i>
            </div>
            <div class="integration-8" data-bs-toggle="tooltip" data-bs-placement="top" title="Angular">
              <i class="fa-2x dev devicon-angularjs-plain colored"></i>
            </div>
            <div class="integration-9" data-bs-toggle="tooltip" data-bs-placement="top" title="Flutter">
              <i class="fa-2x dev devicon-flutter-plain colored"></i>
            </div>

            <div class="integration-10" data-bs-toggle="tooltip" data-bs-placement="top" title="Swift">
              <i class="fa-2x dev devicon-swift-plain colored"></i>
            </div>
            <div class="integration-11" data-bs-toggle="tooltip" data-bs-placement="top" title="Kotlin">
              <i class="fa-2x dev devicon-kotlin-plain colored"></i>
            </div>
            <div class="integration-12" data-bs-toggle="tooltip" data-bs-placement="top" title="Wordpress">
              <i class="fa-2x dev devicon-mongodb-plain colored"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-100">
      <div class="col-lg-5 col-md-12">
        <a
          class="mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5">
          <div class="position-relative connected-app-content">
            <div class="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
              <img src="assets/img/integations/aws.png" width="40" alt="integration" class="img-fluid">
            </div>
            <h5>Amazon Web Services</h5>
            <p class="mb-0 text-body">For large scale projects with needs to handle high volume traffic, our goto platform (and partner) is AWS. The client may however decide if need be to use a different hosting provider.</p>
          </div>
          <span class="position-absolute integration-badge badge px-3 py-2 bg-primary-soft text-primary">Ideal
            Budget</span>
        </a>
      </div>

      <div class="col-lg-5 col-md-12">
        <a
          class="position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5">
          <div class="position-relative connected-app-content">
            <div class="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
              <img src="assets/img/integations/digital-ocean.png" width="40" alt="integration" class="img-fluid">
            </div>
            <h5>DigitalOcean</h5>
            <p class="mb-0 text-body">We typically use DigitalOcean to host our low traffic projects without sacrificing on performance. Using the hosting provider of your choice is still possible, just let us know about your needs and current stack.</p>
          </div>
          <span class="position-absolute integration-badge badge px-3 py-2 bg-danger-soft text-danger">Low Budget</span>
        </a>
      </div>
    </div>
  </div>
</section>
<!--integration section end-->

<!--cat subscribe start-->
<section class="cta-subscribe ptb-50">
  <div class="container">
    <div class="bg-dark ptb-120 position-relative overflow-hidden rounded-custom">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-8">
          <div class="subscribe-info-wrap text-center position-relative z-2">
            <div class="section-heading">
              <h4 class="h5 text-pink">Get Free Support</h4>
              <h2>Let's talk about your project</h2>
              <p>We can help implement your project for better business revenue.</p>
            </div>
            <div class="form-block-banner mw-60 m-auto mt-5">
              <a routerLink="." [fragment]="'footer'" skipLocationChange class="btn btn-pink">Contact with Us</a>
            </div>
            <ul class="nav justify-content-center subscribe-feature-list mt-4">
              <li class="nav-item">
                <span><i class="far fa-check-circle text-primary me-2"></i>We respond in less than 24H</span>
              </li>
              <li class="nav-item">
                <span><i class="far fa-check-circle text-primary me-2"></i>Free tech consultation</span>
              </li>
              <li class="nav-item">
                <span><i class="far fa-check-circle text-primary me-2"></i>Provide an estimate in 3 Days</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
      <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-pinkright-5"></div>
    </div>
  </div>
</section>
<!--cat subscribe end-->